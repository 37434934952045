import React, { Component } from 'react'

import { MoreProductsContext } from '../../../../utils'
import MoreProductsList from './MoreProductsList'

export default class MoreProducts extends Component {
  renderProducts = (context) => {
    const { id, groupedrelations = [] } = this.props.productData
    const products = []

    if (context) {
      // return an empty list when there is no provider
      const { groupedRelations } = context
      groupedrelations.map((childId) => {
        const childData = groupedRelations[childId]
        if (!!childData && childData.id !== id && childData.active === true) {
          // filter out ourself, filter out inactive products
          products.push(childData)
        }
      })
    }

    return <MoreProductsList moreProducts={products} />
  }

  render() {
    return (
      <MoreProductsContext.Consumer>
        {(context) => this.renderProducts(context)}
      </MoreProductsContext.Consumer>
    )
  }
}
