import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'

import { fetchMakairaProducts } from '../../../utils'
import { withTranslation, withFeatureFlags } from '../../../utils/providers'

const MoreProductsContext = React.createContext()

/**
 * Fetch and cache the product data of variants for a given list of products.
 */
class MoreProductsProvider extends Component {
  static propTypes = {
    /**
     * List of products, for which the product data of variants is to be fetched.
     */
    products: PropTypes.array,
    /**
     * The base product is part of the 'groupedrelations' list.
     * Should the base product itself be filtered out of the variants data?
     *
     * Default: true
     */
    filterCurrentProduct: PropTypes.bool,
  }

  state = {
    groupedRelations: {},
    isLoading: false,
  }

  componentDidMount() {
    this.updateGroupedRelations()
  }

  componentDidUpdate(prevProps) {
    const oldProducts = prevProps.products.map((p) => p.id).sort()
    const newProducts = this.props.products.map((p) => p.id).sort()
    if (!isEqual(oldProducts, newProducts)) {
      this.updateGroupedRelations()
    }
  }

  updateGroupedRelations = async () => {
    const { isFeatureEnabled } = this.props
    if (!isFeatureEnabled('relatedProducts')) {
      return
    }
    const { products, filterCurrentProduct = true, language } = this.props
    let groupedIds = [] // Collect product-ID's of relatated products ("Varianten")

    // Collect related product-ID's from the parents
    products.map((product) => {
      if (product.fields?.groupedrelations) {
        let children
        if (filterCurrentProduct) {
          children = product.fields.groupedrelations.filter((relId) => {
            return relId != product.id // filter out ourself
          })
        } else {
          children = product.fields.groupedrelations // we need current product for new Card List Product view.
        }
        groupedIds = [...groupedIds, ...children]
      }
    })

    this.setState({
      isLoading: true,
    })

    // Map related products to their product-ID for easy access
    const results = {}

    // Request product data of related products
    await fetchMakairaProducts(groupedIds, language)
      .then((productDataArray) => {
        productDataArray?.map((product) => {
          //product.groupedrelations = [] // Remove nested relations to break recursion
          results[product.id] = product
        })
      })
      .catch(console.error)

    this.setState({
      groupedRelations: results,
      isLoading: false,
    })
  }

  render() {
    const value = this.state
    return (
      <MoreProductsContext.Provider value={value}>
        {this.props.children}
      </MoreProductsContext.Provider>
    )
  }
}

export default withFeatureFlags(withTranslation(MoreProductsProvider))
export { MoreProductsContext }
