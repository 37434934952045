import { getProductPrice } from '../../index'

import { pushDataLayer, clearGA4Data } from './dataLayer'

/**
 * Fires a 'productImpressions' event to track the impression of a product.
 *
 * @param {Object} product - The product object containing information such as manufacturer_name, produkt_linie, name, config_variable_a, id, sku, title, and price.
 * @param {number} position - The position of the product in the list or grid.
 * @param {string[]} categoryUrls - An array of category URLs related to the product.
 * @param {Object} searchData - The search data object containing information about the search query and result.
 */
function trackProductImpression(
  {
    manufacturer_name,
    produkt_linie,
    name: productName,
    config_variable_a,
    id,
    sku,
    title,
    priceinformation,
  },
  position,
  categoryUrls,
  searchData
) {
  const name = `${manufacturer_name} ${produkt_linie} ${productName} ${config_variable_a}`

  const productData = {
    brand: manufacturer_name,
    list: 'category',
    id,
    sku,
    name,
    position,
  }

  const ecommerce = {
    currencyCode: process.env.CURRENCY,
    impressions: [productData],
    payload: '{}',
  }

  if (categoryUrls?.length > 0) {
    ecommerce.payload = JSON.stringify({
      categoryUrl: categoryUrls[0],
      magentoCategoryPath: categoryUrls[1],
      mCategoryPath: categoryUrls[2],
    })
  } else if (searchData && Object.keys(searchData).length > 0) {
    ecommerce.payload = JSON.stringify(searchData)
  }
  const productPrice = getProductPrice(priceinformation[0])
  const tagManagerDataLayerArgs = {
    dataLayer: {
      event: 'productImpressions',
      ecommerce: ecommerce,
      ga4: {
        currencyCode: process.env.CURRENCY,
        totalValue: productPrice,
        items: [
          {
            item_id: sku,
            item_name: title,
            item_variant: config_variable_a,
            price: productPrice,
            quantity: 1,
          },
        ],
      },
    },
  }
  clearGA4Data()
  pushDataLayer(tagManagerDataLayerArgs)
}

export default trackProductImpression
