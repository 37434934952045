import React, { useState } from 'react'

import {
  ConditionalProductLink,
  Image,
  ProductActionButton,
  ProductBadges,
  ProductRating,
} from '../..'
import ProductPrices from '../PriceInfo/ProductPrices'
import ProductPriceHint from '../PriceInfo/ProductPriceHint'
import ProductLink from '../Link/ProductLink'
import QtySelect from '../QtySelect'

import {
  getTierIconClass,
  isFixedBundle,
  useFeatureFlags,
} from '../../../utils'

import MoreProducts from './MoreProducts'

export default function ProductItemList({
  productData,
  ratingSummary,
  isNested = false,
  trackingData,
}) {
  const [qty, setQty] = useState(1)
  const { isFeatureEnabled } = useFeatureFlags()

  // The QtySelect is not supposed to be visible for configurable bundle
  // products, but will be displayed in the QuickviewPopup. Hence,
  // "ProductActionButton" is supposed to be "Choose Variant". This variable
  // is used for that as well.
  // See "disableVariants" property of "ProductActionButton" below.
  let showQtyBox = false
  switch (productData.product_type) {
    case 'simple':
    case 'virtual':
      showQtyBox = true
      break
    case 'bundle':
      showQtyBox = isFixedBundle(productData)
      break
  }

  const tierIconClass = getTierIconClass(productData.tiericon)
  const imgUrl = productData.small_image
    ? productData.small_image
    : productData.images[0]

  return (
    <>
      <form
        className="product-item__form p-3 product-item-list"
        action=""
        method="post"
        data-id={productData.id}
        data-sku={productData.sku}
      >
        <div className="row product-row mx-0 in-stock">
          <div className="col-4 col-lg-2 text-center pl-0">
            {isFeatureEnabled('product.badges') && (
              <ProductBadges badge={productData.badge} />
            )}
            <ConditionalProductLink
              href={productData.url}
              className="product-item__image"
            >
              <Image srcFull={imgUrl} />
            </ConditionalProductLink>
          </div>
          <div className="col-5 d-flex flex-column">
            <ProductLink
              href={productData.url}
              className="font-weight-semi-bold font-size-m"
            >
              <span>{productData.manufacturer_name}</span>{' '}
              <span>{productData.produkt_linie}</span>
            </ProductLink>
            <span className="d-block">{productData.name}</span>
            <span className="d-block">{productData.config_variable_a}</span>
            <span className="d-block">
              {tierIconClass.map((iconClass, index) => {
                return <i key={index} className={iconClass} />
              })}
            </span>
            {ratingSummary && (
              <ProductRating
                productData={productData}
                ratingSummary={ratingSummary}
              />
            )}
          </div>

          <div className="col-3 col-lg-2 text-left">
            {showQtyBox && (
              <div className="product-item__qty select-box">
                <QtySelect
                  isDisabled={!productData.availability}
                  min={1}
                  max={10}
                  qty={qty}
                  changeQty={setQty}
                  type="selectBox"
                  productId={productData.id}
                />
              </div>
            )}
          </div>
          <div className="col-12 col-lg-3 text-right pr-lg-0 d-flex flex-column">
            <ProductPrices
              productData={productData}
              classSpecialPrice="font-size-m d-block"
              classRegularPrice="d-block"
              classUnitPrice="font-size-xxs d-block"
              forPage="listing"
            />
            <ProductPriceHint className="mb-3 font-size-xxs" />
            <ProductActionButton
              productData={productData}
              qty={qty}
              disableVariants={showQtyBox}
              trackingData={trackingData}
              className="w-100"
            />
          </div>
        </div>
      </form>
      {!isNested && <MoreProducts productData={productData} />}
    </>
  )
}
