import React, { Component } from 'react'

import { Button } from '../..'
import { TranslationContext } from '../../../utils'

export default class Pagination extends Component {
  static contextType = TranslationContext

  constructor(props) {
    super(props)

    const { queryParams = {} } = props
    const count = queryParams.count ?? process.env.PRODUCTS_PER_PAGE
    const offset = queryParams.offset ?? 0
    const currentPageNr = offset / count + 1

    this.state = { currentPageNr }
  }

  calculateTotalNumberOfPages = () => {
    const { queryParams = {}, totalProductCount = 0 } = this.props
    const count = queryParams.count ?? process.env.PRODUCTS_PER_PAGE

    return Math.ceil(totalProductCount / count)
  }

  previousPage = () => {
    this.setState((prevState) => {
      return {
        currentPageNr: prevState.currentPageNr - 1,
      }
    }, this.props.submitForms)
  }

  nextPage = () => {
    this.setState((prevState) => {
      return {
        currentPageNr: prevState.currentPageNr + 1,
      }
    }, this.props.submitForms)
  }

  firstPage = () => {
    this.setState({ currentPageNr: 1 }, this.props.submitForms)
  }

  lastPage = () => {
    const lastPage = this.calculateTotalNumberOfPages()

    this.setState({ currentPageNr: lastPage }, this.props.submitForms)
  }

  render() {
    const { t } = this.context || {}
    const { currentPageNr } = this.state
    const totalNrOfPages = this.calculateTotalNumberOfPages()
    const isFirstPage = currentPageNr === 1
    const isLastPage = currentPageNr === totalNrOfPages

    const canJumpToFirst = currentPageNr > 1
    const canJumpToLast = totalNrOfPages > 1 && currentPageNr < totalNrOfPages

    if (totalNrOfPages <= 1) return null

    return (
      <form className="product-list__pagination mb-4">
        <input type="hidden" name="pageNumber" value={currentPageNr} />

        <Button
          className="btn-pagination"
          variant={null}
          disabled={!canJumpToFirst}
          onClick={this.firstPage}
          title={t('PRODUCT_LIST_FIRST_PAGE')}
        >
          <i className="cf-prev"></i>
          <i className="cf-prev"></i>
        </Button>
        <Button
          className="btn-pagination"
          variant={null}
          disabled={isFirstPage}
          onClick={this.previousPage}
          title={t('PRODUCT_LIST_PREV_PAGE')}
        >
          <i className="cf-prev"></i>
        </Button>

        <span className="product-list__pagination-text">
          {t('PRODUCT_LIST_CURRENT_PAGE')?.(currentPageNr, totalNrOfPages)}
        </span>

        <Button
          className="btn-pagination"
          variant={null}
          disabled={isLastPage}
          onClick={this.nextPage}
          title={t('PRODUCT_LIST_NEXT_PAGE')}
        >
          <i className="cf-next"></i>
        </Button>
        <Button
          className="btn-pagination"
          variant={null}
          disabled={!canJumpToLast}
          onClick={this.lastPage}
          title={t('PRODUCT_LIST_LAST_PAGE')}
        >
          <i className="cf-next"></i>
          <i className="cf-next"></i>
        </Button>
      </form>
    )
  }
}
