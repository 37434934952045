import { trackProductImpression } from '../tracking'

export default function setupProductImpressionTracking({
  ref = {},
  productList,
  categoryUrls,
  searchData,
}) {
  const { current: containingElement } = ref
  if (!containingElement) {
    return
  }
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    const defaultOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    }
    const pushedProducts = []
    const callback = (entries) => {
      let i = 0
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!pushedProducts.includes(entry.target.dataset.selector)) {
            pushedProducts[i] = entry.target.dataset.selector
            i += 1
            const selector = entry.target.dataset.selector
            const selectorId = selector.match(/\d+/)[0]
            const selectorPosition = entry.target.dataset.position
            const product = productList.filter((product) => {
              if (product.fields.id == selectorId) {
                return product
              }
            })
            if (window.userTrackingEnabled) {
              trackProductImpression(
                product[0].fields,
                selectorPosition,
                categoryUrls,
                searchData
              )
            }
            observer.unobserve(entry.target)
          }
        }
      })
    }

    const observer = new IntersectionObserver(callback, defaultOptions)
    containingElement.querySelectorAll(`[data-selector]`).forEach((element) => {
      observer.observe(element)
    })
  }
}
