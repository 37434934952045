import React from 'react'
import { ListPagePricingProvider } from '../../../utils'
import ProductItemList from './ProductItemList'
import ProductItemGrid from './ProductItemGrid'

export default function Row(props) {
  const { isMobile } = props

  // If 'isMobile' is not defined, fall back to desktop mode
  if (isMobile) {
    return (
      <ListPagePricingProvider productData={props.productData}>
        <ProductItemGrid {...props} />
      </ListPagePricingProvider>
    )
  } else {
    return (
      <ListPagePricingProvider productData={props.productData}>
        <ProductItemList {...props} />
      </ListPagePricingProvider>
    )
  }
}
