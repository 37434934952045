import React, { Component } from 'react'
import { SlideDown } from 'react-slidedown'
import classNames from 'classnames'

import Row from '../Row'
import { fetchRatings, TranslationComponent } from '../../../../utils'
import { withFeatureFlags } from '../../../../utils/providers'

class MoreProductsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpened: false, // are more products being shown right now?
      isLoaded: false, // have ratings been requested already?
      isLoading: false, // are ratings being fetched right now?
      productRatings: null,
    }
  }

  loadMore = async () => {
    const moreProducts = this.props.moreProducts
    if (moreProducts) {
      try {
        this.setState({
          isLoading: true,
        })
        if (this.props.isFeatureEnabled('product.ratings')) {
          const results = await fetchRatings(moreProducts)
          this.setState({
            productRatings: results,
          })
        }
      } catch (error) {
        // Failing to load reviews is not critical, so a console message should suffice
        console.error(error)
      }
      this.setState({
        isLoading: false,
        isLoaded: true,
        isOpened: true,
      })
    }
  }

  onClick = () => {
    if (!this.state.isLoaded) {
      this.loadMore()
    } else {
      this.setState({
        isOpened: !this.state.isOpened,
      })
    }
  }

  render() {
    const { moreProducts = [] } = this.props
    if (!moreProducts.length) {
      return null
    }

    const { isOpened, isLoading, productRatings } = this.state

    const buttonText = isOpened ? 'SHOW_LESS' : 'MORE_VARIANTS'
    const className = classNames(
      'text-center font-size-xs show-more pt-3 pt-lg-0',
      {
        collapsed: isOpened,
        loading: isLoading,
      }
    )

    return (
      <>
        <SlideDown>
          {isOpened && (
            <div className="child-products">
              {moreProducts.map((product, index) => {
                const rating = productRatings
                  ? productRatings[product.id]
                  : null
                return (
                  <Row
                    key={index}
                    isNested={true}
                    ratingSummary={rating}
                    productData={product}
                  />
                )
              })}
            </div>
          )}
        </SlideDown>

        <div className={className} onClick={this.onClick}>
          <span>
            <TranslationComponent text={buttonText} />
          </span>
        </div>
      </>
    )
  }
}

export default withFeatureFlags(MoreProductsList)
