import React from 'react'
import PropTypes from 'prop-types'

import { ContentElements, LoadingSpinner } from '../../index'
import { useSnippet } from '../../../utils'

function SnippetContent(props) {
  const { snippetId, showSpinner = false, render } = props
  const elements = useSnippet(snippetId)

  if (!elements?.length) {
    if (showSpinner) {
      return <LoadingSpinner />
    }
    return null
  }

  const content = <ContentElements elements={elements} />
  if (typeof render === 'function') {
    return render(content)
  }
  return content
}

SnippetContent.propTypes = {
  /**
   * The snippet to show.
   */
  snippetId: PropTypes.string.isRequired,
  /**
   * Show a spinner while the snippet is not available? Default to 'false'
   */
  showSpinner: PropTypes.bool,
  /**
   * An optional render function
   *
   * @param {React.Component} content - The content that would be rendered without the render function.
   */
  render: PropTypes.func,
}

export default SnippetContent
