import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'

import { useLazyLoading, useMediaResize } from '../../../utils'
import Pagination from './Pagination'
import Row from './Row'
import setupProductImpressionTracking from '../../../utils/Alphapet/setupProductImpressionTracking'

export default function List(props) {
  const {
    products = [],
    queryParams = {},
    totalProductCount = 0,
    submitForms,
    isLoading = false,
    productRatings = null,
    categoryUrls,
    searchData,
    trackingData,
  } = props
  const listRef = useRef(null)

  useLazyLoading({ ref: listRef, dependency: products })

  // Keep 'isMobile' state here, to avoid having to watch multiple states for
  // each '<Row />' component.
  const { breakpoints, initialDevice } = useMediaResize()
  const [isMobile, setMobile] = useState(initialDevice === 'mobile')
  useEffect(() => {
    setMobile(breakpoints.downLG)
  })
  useEffect(() => {
    setupProductImpressionTracking({
      ref: listRef,
      productList: products,
      categoryUrls: categoryUrls,
      searchData: searchData,
    })
  }, [listRef, products])

  const classes = classNames('product-list__list', {
    ['product-list__list--loading']: isLoading,
  })

  return (
    <div ref={listRef} className={classes}>
      {products.map(({ fields }) => {
        const productRating =
          productRatings && productRatings[fields.id] != null
            ? productRatings[fields.id]
            : null
        return (
          <div
            key={fields.id}
            className="position-relative shadow-sm mb-4"
            data-selector={`pi-${fields.id}`}
          >
            <Row
              isMobile={isMobile}
              ratingSummary={productRating}
              productData={fields}
              trackingData={trackingData}
            />
          </div>
        )
      })}

      <Pagination
        key={queryParams.offset ?? 0} // reset Pagination to re-run constructor when offset change (e.g. when a filter is clicked)
        queryParams={queryParams}
        totalProductCount={totalProductCount}
        submitForms={submitForms}
      />
    </div>
  )
}

export { default as productListVariants } from './variants.js'
